import React from 'react';
import topFaceSVG from '../../../assets/top.svg';
import styles from '../../../styles/Face.module.scss';

export interface TopFaceProps {
  length: number;
  breadth: number;
}

export const TopFace: React.FC<TopFaceProps> = ({ length, breadth }) => (
  <div
    className={styles.TopFace}
    style={{
      backgroundImage: `url('${topFaceSVG}')`,
      backgroundSize: `${length}px ${breadth}px`,
      width: `${length}px`,
      height: `${breadth}px`
    }}
  />
);
