import React from 'react';
import { FrontFace, FrontFaceProps } from './FrontFace';
import { RightFace, RightFaceProps } from './RightFace';
import { TopFace, TopFaceProps } from './TopFace';
import { Warning, WarningProps } from './Warning';
import styles from '../../../styles/Box.module.scss';

export interface BoxProps
  extends FrontFaceProps,
    RightFaceProps,
    TopFaceProps,
    WarningProps {
  dimensions: {
    heightInCentimeters?: number;
    lengthInCentimeters?: number;
    breadthInCentimeters?: number;
  };
  isDefined: boolean;
  heightIndicatorOffset: number;
  packageOffset: number;
  compareViewDimensions: { width: number; height: number };
}

export const Box: React.FC<BoxProps> = ({
  warningMessage,
  dimensions,
  isDefined,
  length,
  height,
  compareViewDimensions,
  heightIndicatorOffset,
  packageOffset,
  fontSize,
  warningBackground,
  breadth
}) => (
  <div
    className={styles.Package}
    style={{
      maxWidth: `${Math.floor(compareViewDimensions.width / 2)}px`,
      marginBottom: `${height + 63}px`,
      marginLeft: `${isDefined ? packageOffset : 0}px`
    }}
  >
    {isDefined ? (
      <>
        <FrontFace length={length} height={height} />
        <RightFace breadth={breadth} length={length} height={height} />
        <TopFace breadth={breadth} length={length} />
      </>
    ) : (
      <Warning
        warningMessage={warningMessage}
        length={length}
        height={height}
        fontSize={fontSize}
        warningBackground={warningBackground}
      />
    )}
    <div
      className={styles.Indicator}
      style={{
        left: `${isDefined ? heightIndicatorOffset : 80}px`,
        marginTop: `${isDefined ? height / 2 - fontSize - 10 : 66}px`
      }}
    >
      {(isDefined ? dimensions.heightInCentimeters : '?') + ' H'}
    </div>
    <div
      className={styles.Indicator}
      style={{
        left: `${isDefined ? length / 2 - 25 : 140}px`,
        marginTop: `${isDefined ? height - 5 : 138}px`
      }}
    >
      {(isDefined ? dimensions.lengthInCentimeters : '?') + ' L'}
    </div>
    <div
      className={styles.Indicator}
      style={{
        left: `${isDefined ? length + 25 : 262}px`,
        marginTop: `${isDefined ? height - 10 : 134}px`
      }}
    >
      {(isDefined ? dimensions.breadthInCentimeters : '?') + ' B'}
    </div>
  </div>
);

export default Box;
