import React from 'react';
import rightFaceSVG from '../../../assets/right.svg';
import styles from '../../../styles/Face.module.scss';

export interface RightFaceProps {
  breadth: number;
  length: number;
  height: number;
}

export const RightFace: React.FC<RightFaceProps> = ({
  breadth,
  length,
  height
}) => (
  <div
    className={styles.RightFace}
    style={{
      backgroundImage: `url('${rightFaceSVG}')`,
      backgroundSize: `${breadth}px ${height}px`,
      width: `${breadth}px`,
      height: `${height}px`,
      transform: `translateX(${length}px) rotateX(-10deg) rotateY(-25deg) rotateY(90deg)`
    }}
  />
);
