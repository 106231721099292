import React from 'react';
import frontFaceSVG from '../../../assets/front.svg';
import styles from '../../../styles/Face.module.scss';

export interface FrontFaceProps {
  length: number;
  height: number;
}

export const FrontFace: React.FC<FrontFaceProps> = ({ length, height }) => (
  <div
    className={styles.FrontFace}
    style={{
      backgroundImage: `url('${frontFaceSVG}')`,
      backgroundSize: `${length}px ${height}px`,
      width: `${length}px`,
      height: `${height}px`
    }}
  />
);
