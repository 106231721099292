import React from 'react';
import WarningBoxSVG from '../../../assets/warning-box.svg';
import { WarningBackground } from '../PackageComparator';
import styles from '../../../styles/Warning.module.scss';

export interface WarningProps {
  warningMessage?: string;
  length: number;
  height: number;
  fontSize: number;
  warningBackground: string;
}

export const Warning: React.FC<WarningProps> = ({
  warningMessage = 'There are no privided informations about the package size',
  length,
  height,
  fontSize,
  warningBackground
}) => (
  <>
    <div
      className={styles.Container}
      style={{
        padding: fontSize,
        backgroundColor:
          warningBackground === WarningBackground.default
            ? '#ffde00'
            : 'transparent',
        textAlign:
          warningBackground === WarningBackground.default ? 'initial' : 'center'
      }}
    >
      <div
        style={{
          borderTop:
            warningBackground === WarningBackground.default
              ? `${fontSize * 0.1}px solid black`
              : 'none'
        }}
      >
        <span className={styles.Text}>{warningMessage}</span>
      </div>
    </div>
    <div
      className={styles.FallbackPackage}
      style={{
        width: `${length}px`,
        height: `${height}px`,
        backgroundImage: `url('${WarningBoxSVG}')`
      }}
    />
  </>
);
