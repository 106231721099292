import React, { useState, useEffect, useCallback } from 'react';
import { Box, BoxProps } from './Box';
import { Human, HumanProps } from './Human';
import styles from '../../styles/PackageComparator.module.scss';

export enum WarningBackground {
  default = 'default',
  transparent = 'transparent'
}

declare global {
  interface Document {
    documentMode?: any;
  }
}

interface Dimensions {
  height?: number;
  length?: number;
  breadth?: number;
}

interface PackageComparatorProps {
  dimensions: Dimensions;
  warningMessage?: string;
  warningBackground: WarningBackground;
  viewportWidth: number;
  viewportHeight: number;
}

interface PackageComparatorState extends Omit<BoxProps, 'warningMessage' | 'dimensions'>, HumanProps {}

const DefaultDimensions = {
  height: 60,
  length: 100,
  breadth: 20
};

/**
 * warningBackground can be equal to 'default' or 'transparent'
 */
export const PackageComparator: React.FC<PackageComparatorProps> = ({
  dimensions,
  warningMessage,
  warningBackground,
  viewportHeight,
  viewportWidth
}) => {
  const [state, setState] = useState<PackageComparatorState>({
    humanHeightInCentimeters: 170,
    fontSize: 30,
    height: 0,
    length: 0,
    breadth: 0,
    humanHeightInPixels: 0,
    heightIndicatorOffset: 0,
    packageOffset: 0,
    compareViewDimensions: {
      width: 0,
      height: 0
    },
    warningBackground: WarningBackground.default,
    isDefined: false
  });

  const calculateAllDimensions = useCallback(() => {
    const prevState = state;
    let height: number;
    let length: number;
    let breadth: number;
    let isDefined: boolean;
    const referenceSize = Math.min(viewportHeight, viewportWidth);

    const calculateDimensionInPixels = (dimension: number) =>
      Math.floor(
        (referenceSize * 0.7 * dimension) / prevState.humanHeightInCentimeters
      );

    if (!dimensions.height || !dimensions.length || !dimensions.breadth) {
      height = DefaultDimensions.height;
      length = DefaultDimensions.length;
      breadth = DefaultDimensions.breadth;
      isDefined = false;
    } else {
      height = dimensions.height;
      length = dimensions.length;
      breadth = dimensions.breadth;
      isDefined = true;
    }

    const heightInPixels = calculateDimensionInPixels(height);
    const lengthInPixels = calculateDimensionInPixels(length);
    const breadthInPixels = calculateDimensionInPixels(breadth);
    const humanHeightInPixels = Math.floor(referenceSize * 0.7);
    const fontSize = Math.min(Math.floor(referenceSize * 0.04), 30);

    const warningBg = warningBackground;

    const absoluteFrontFaceWidth =
      Math.cos(10 * (Math.PI / 180)) *
      Math.cos(25 * (Math.PI / 180)) *
      lengthInPixels;

    const heightIndicatorOffset =
      ((height >= 100 ? -70 : height >= 10 ? -54 : -38) +
        (length >= 100 ? -3 : length >= 50 ? -2 : 0) +
        (lengthInPixels - absoluteFrontFaceWidth) -
        12) *
      fontSize *
      0.04;

    const packageOffset =
      ((height >= 100 ? 15 : height >= 10 ? 0 : -15) +
        (-lengthInPixels + absoluteFrontFaceWidth + 47)) *
      fontSize *
      0.04;

    const newState = Object.assign({}, prevState, {
      height: heightInPixels,
      length: lengthInPixels,
      breadth: breadthInPixels,
      compareViewDimensions: {
        width: viewportWidth,
        height: viewportWidth
      },
      humanHeightInPixels,
      fontSize,
      warningBackground: warningBg,
      heightIndicatorOffset,
      packageOffset,
      isDefined
    });

    setState(newState);
  },// eslint-disable-next-line react-hooks/exhaustive-deps 
  [ 
    dimensions.breadth,
    dimensions.height,
    dimensions.length,
    warningBackground
  ]);

  useEffect(() => {
    calculateAllDimensions();
  }, [calculateAllDimensions]);

  return (
    <div
      className={styles.CompareView}
      style={{
        fontSize: state.fontSize
      }}
    >
      <Human
        humanHeightInCentimeters={state.humanHeightInCentimeters}
        humanHeightInPixels={state.humanHeightInPixels}
        compareViewDimensions={state.compareViewDimensions}
      />
      <Box
        dimensions={{
          heightInCentimeters: dimensions.height,
          lengthInCentimeters: dimensions.length,
          breadthInCentimeters: dimensions.breadth
        }}
        warningMessage={warningMessage}
        isDefined={state.isDefined}
        breadth={state.breadth}
        length={state.length}
        height={state.height}
        heightIndicatorOffset={state.heightIndicatorOffset}
        packageOffset={state.packageOffset}
        compareViewDimensions={state.compareViewDimensions}
        fontSize={state.fontSize}
        warningBackground={state.warningBackground}
      />
    </div>
  );
};

export default PackageComparator;
