import React from 'react';
import scaleSVG from '../../assets/scale.svg';
import humanSVG from '../../assets/human.svg';
import styles from '../../styles/Human.module.scss';

export interface HumanProps {
  humanHeightInCentimeters: number;
  humanHeightInPixels: number;
  compareViewDimensions: { width: number; height: number };
}

export const Human: React.FC<HumanProps> = ({
  humanHeightInCentimeters,
  humanHeightInPixels,
  compareViewDimensions
}) => (
  <div
    className={styles.Container}
    style={{
      width: `${humanHeightInPixels * 0.8}px`,
      height: `${humanHeightInPixels}px`,
      maxWidth: `${Math.floor(compareViewDimensions.width / 2)}px`
    }}
  >
    <div
      className={styles.Scale}
      style={{
        backgroundImage: `url('${scaleSVG}')`,
        height: `${humanHeightInPixels}px`,
        width: `${humanHeightInPixels * 0.3}px`
      }}
    >
      <div className={styles.HumanHeightIndicator}>
        {humanHeightInCentimeters} cm
      </div>
    </div>
    <div
      className={styles.Body}
      style={{
        backgroundImage: `url('${humanSVG}')`,
        height: `${humanHeightInPixels}px`,
        width: `${humanHeightInPixels * 0.4}px`
      }}
    />
  </div>
);
