import React from 'react';
import {
  WarningBackground,
  PackageComparator
} from './components/PackageComparator';
import styles from './App.module.scss';

const viewport = { height: 600, width: 800 };

const App: React.FC = () => {
  return (
    <div
      className={styles.Container}
      style={{
        width: viewport.width,
        height: viewport.height
      }}
    >
      <PackageComparator
        dimensions={{ length: 43, height: 43, breadth: 34 }}
        warningMessage='Keine Informationen zur Packungsgröße verfügbar'
        warningBackground={WarningBackground.transparent}
        viewportHeight={viewport.height}
        viewportWidth={viewport.width}
      />
    </div>
  );
};

export default App;
